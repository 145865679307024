import request from '@/utils/axios'

export function domainAdd (data) {
  return request({
    url: '/api/console/domain_flows',
    method: 'post',
    data: data
  })
}

export function domainEdit (id, data) {
  return request({
    url: `/api/console/domain_flows/${id}`,
    method: 'post',
    data: data,
  })
}

export function domainDetail (id) {
  return request({
    url: `/api/console/domain_flows/${id}`,
    method: 'get'
  })
}

export function domainDelete (id) {
  return request({
    url: `/api/console/domain_flows/${id}`,
    method: 'delete'
  })
}

export function domainList (params) {
  return request({
    url: '/api/console/domain_flows',
    method: 'get',
    params: params
  })
}

export function domainChangeStatus (id, status) {
  return request({
    url: `/api/console/domain_flows/${id}`,
    method: 'get',
    params: {
      is_visible: status
    }
  })
}