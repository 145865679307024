const options = {
  pushTargetList: [
    {
      label: '單一用戶',
      value: 'single'
    },
    {
      label: '全体用户',
      value: 'all'
    }
  ],
  pushTypeList: [
    {
      label: '系統',
      value: 'system'
    },
    {
      label: '客服',
      value: 'customer_service'
    }
  ],
  categoryList: [
    {
      label: '彩民',
      value: '1'
    },
    {
      label: '竞猜师',
      value: '2'
    }
  ],
  stateList: [
    {
      label: '未处理',
      value: 'init'
    },
    {
      label: '已回覆',
      value: 'replied'
    },
    {
      label: '已读取',
      value: 'read'
    },
    {
      label: '已删除',
      value: 'deleted'
    },
    {
      label: '无违规',
      value: 'conform'
    },
    {
      label: '已拒绝',
      value: 'rejected'
    },
    {
      label: '已接受',
      value: 'accepted'
    }
  ],
  entranceList: [
    {
      label: '玩家',
      value: 'user'
    },
    {
      label: '商業用戶',
      value: 'business'
    }
  ],
  channelList: [
    {
      label: '线路1',
      value: '1'
    },
    {
      label: '线路2',
      value: '2'
    },
    {
      label: '线路3',
      value: '3'
    }
  ]
}

export default options
