<template>
  <div id="form">
    <el-form
      :ref="formName"
      :model="query"
      :rules="queryRule"
      label-width="120px"
      label-position="left">
      <el-form-item
        align="left"
        label="渠道入口"
        prop="entrance">
        <el-radio-group v-model="query.entrance">
          <el-radio
            v-for="item in entranceList"
            :key="item.value"
            :label="item.value">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        align="left"
        label="线路"
        prop="channel">
        <el-radio-group v-model="query.channel">
          <el-radio
            v-for="item in channelList"
            :key="item.value"
            :label="item.value">
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="线路域名"
        prop="domain">
        <el-input v-model="query.domain" placeholder="http:// 或 https://" />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer">
      <el-button @click="handleCancel">
        取消
      </el-button>
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="handleSubmit">
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { domainDetail, domainEdit } from '@/api/admin/domain'
import options from '@/utils/options'

export default {
  data () {
    return {
      formName: 'form',
      entranceList: options.entranceList,
      channelList: options.channelList,
      saveLoading: false,
      query: {
        id: null,
        entrance: null,
        channel: null,
        domain: null,
      },
      queryRule: {
        domain: [
          {
            required: true,
            message: '请输入线路域名',
            trigger: 'blur'
          }
        ],
        entrance: [
          {
            required: true,
            message: '请选择渠道入口',
            trigger: 'blur'
          }
        ],
        channel: [
          {
            required: true,
            message: '请选择线路',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  methods: {
    init () {
      domainDetail(this.query.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.query = res.data
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleCancel () {
      this.$router.go(-1)
    },
    handleSubmit () {
      if (this.query.domain && !this.query.domain.includes('http://') && !this.query.domain.includes('https://'))
        return this.$message.error('请输入 http:// 或 https:// 开头的网址')

      this.$refs[this.formName].validate(valid => {
        if (valid)
          this.saveData()
      })
    },
    saveData () {
      this.saveLoading = true
      domainEdit(this.query.id, this.query)
        .then(res => {
          this.saveLoading = false
          switch (res.status) {
            case resStatus.OK:
              this.handleCancel()
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.saveLoading = false
          this.$message.error(err)
        })
    },
  },
  created () {
    this.query.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#form{
  max-width: calc((100vw - 200px) * 0.8);
}
</style>
